import "./styles.scss"

import React from "react"

const data = [
  {
    icon: require("assets/images/tenants/tenants-icon-1.svg").default,
    header: "Oszczędność czasu<br/> i pieniędzy",
    description:
      "Zmiany na etapie budowy są tańsze i szybsze niż późniejszy remont. Koordynacje wszystkich prac zgodnie z harmonogramem bierzemy na siebie. ",
  },
  {
    icon: require("assets/images/tenants/tenants-icon-2.svg").default,
    header: "Wszystko <br/> w jednym miejscu",
    description:
      "Oferujemy kompleksową realizację zmian, więc nie musisz szukać dodatkowych wykonawców ani koordynować prac — wszystko przebiega sprawnie i w jednym miejscu.",
  },
  {
    icon: require("assets/images/tenants/tenants-icon-3.svg").default,
    header: "Bezproblemowe<br/> wykończenie",
    description:
      "Zmiany przed odbiorem mieszkania eliminują potrzebę późniejszych, uciążliwych remontów, dzięki czemu unikasz hałasu, kurzu i bałaganu. Od razu po odbiorze kluczy możesz zacząć urządzać swoje mieszkanie.",
  },
  {
    icon: require("assets/images/tenants/tenants-icon-4.svg").default,
    header: "Optymalnie zaplanowane instalacje",
    description:
      "Wprowadzanie zmian na etapie budowy pozwala lepiej zaplanować instalacje techniczne (elektryka, hydraulika, wentylacja czy ogrzewanie), dzięki czemu Twoja codzienność stanie się jeszcze prostsza.",
  },
  {
    icon: require("assets/images/tenants/tenants-icon-5.svg").default,
    header: "Wyższa wartość nieruchomości",
    description:
      "Mieszkanie dostosowane do indywidualnych preferencji może być bardziej atrakcyjne na rynku i zyskać na wartości przy sprzedaży.",
  },
  {
    icon: require("assets/images/tenants/tenants-icon-6.svg").default,
    header: "Gwarantowana<br/> jakość",
    description:
      "Prace wykonywane przez dewelopera objęte są gwarancją, więc masz pewność, że będą zgodne z normami budowlanymi.",
  },
  {
    icon: require("assets/images/tenants/tenants-icon-7.svg").default,
    header: "Gwarancja ceny i brak ukrytych kosztów",
    description:
      "Masz pewność stałej ceny, bez dodatkowych opłat. Przedstawiamy dokładny kosztorys przed podpisaniem umowy.",
  },
  {
    icon: require("assets/images/tenants/tenants-icon-8.svg").default,
    header: "Optymalizacja funkcjonalności",
    description:
      "Możliwość zmiany układu pomieszczeń pozwala na lepsze wykorzystanie przestrzeni.",
  },
]

const Icons = () => {
  return (
    <section className="tenants-icons">
      <div className="container-fluid">
        <h3>Korzyści</h3>
        <div className="row">
          {data?.map((item, index) => (
            <div className="col-lg-3 col-md-4 col-sm-6" key={index}>
              <div className="tenants-icons__item">
                <img src={item?.icon} alt={item?.header} />
                <h4 dangerouslySetInnerHTML={{ __html: item?.header }}></h4>
                <p>{item?.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Icons
