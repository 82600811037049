import React from "react"
import { withPrefix } from "gatsby"

import Layout from "components/Layout"

import HeaderBackground from "components/HeaderBackground"

import { Content, File, Baner, Grid } from "page_components/subpages"
import { Icons } from "page_components/tenants"

const Friends = ({ location }) => {
  const title = "Zmiany lokatorskie"

  return (
    <Layout
      location={location}
      seo={{
        title: title,
      }}
      mainClass="narrow-layout"
    >
      <HeaderBackground
        title={title}
        header={title}
        description="Wierzymy, że każde mieszkanie powinno być tak unikalne jak jego mieszkańcy, a nawet pozornie drobne modyfikacje mogą przekształcić standardowe wnętrze w przestrzeń, którą będziecie mogli Państwo nazwać swoim miejscem na ziemi."
        background={
          require("assets/images/tenants/tenants-hero-background.jpg").default
        }
        backgroundPosition="top"
      />
      <Content
        header="Mieszkanie skrojone na miarę"
        description="Zmiany lokatorskie pozwalają dostosować układ wnętrz i wyposażenie do Twoich potrzeb. Możesz zmienić rozmieszczenie ścian, punktów elektrycznych czy hydraulicznych, by jeszcze lepiej wykorzystać przestrzeń. Na każdym etapie procesu możesz liczyć na wsparcie naszego doradcy ds. zmian lokatorskich."
        image={
          require("assets/images/tenants/tenants-content-image.jpg").default
        }
      />
      <Icons />
      <Grid
        data={[
          {
            header: "Pełne spektrum możliwych zmian i prac do wykonania",
            description:
              "Kupując mieszkanie, możesz zlecić wiele prac do wykonania już na etapie jego budowy. Oszczędź czas i pieniądze i rozważ, jak chciałbyś zmodyfikować swoje M:",
            image: require("assets/images/tenants/tenants-grid-image-1.jpg")
              .default,
            list: [
              "Instalacja klimatyzacji",
              "Montaż ogrzewania podłogowego",
              "Zmiany w układzie i łączenie mieszkań",
              "Przeniesienie gniazdek, włączników i grzejników",
              "Przeniesienie punktów czerpania wody",
              "Zmiana kierunku otwierania drzwi wejściowych",
            ],
          },
          {
            image: require("assets/images/tenants/tenants-grid-image-2.jpg")
              .default,
            list: [
              "Przesuwne okna i drzwi tarasowe",
              "Montaż dodatkowego szklenia na balkonie/loggii",
              "Wykończenie balkonów/loggii z opcją żywicy lub desek tarasowych",
              "Surowe wykończenie ścian i sufitów",
              "Wyłącznik zasilania z wyłączeniem lodówki i pralki",
              "Wideodomofon",
            ],
          },
          {
            image: require("assets/images/tenants/tenants-grid-image-3.jpg")
              .default,
            list: [
              "Rozwiązania Smart Home",
              "Kinkiet ścienny i gniazdko hermetyczne na balkonie/loggii/tarasie",
              "Zawór czerpalny na tarasie/ogródku",
              "Żelbetowe schody na antresolę",
              "Indywidualna szybka ładowarka samochodowa (do 22 kW)",
              "Gniazdko przy miejscu parkingowych lub w boksie/komórce",
            ],
          },
        ]}
      />
      <File
        header="Zamień mieszkanie<br/> w swoje miejsce na ziemi."
        file={withPrefix("/Trust_Investment_katalog_zmian_lokatorskich.pdf")}
        image={require("assets/images/tenants/tenants-file-cover.png").default}
      />
      <Baner
        header="Mieszkanie unikalne<br/> jak jego mieszkańcy"
        image={
          require("assets/images/tenants/tenants-baner-background.jpg").default
        }
        defaultMessage="Chcę poznać ofertę zmian lokatorskich. Proszę o kontakt."
        withSelect
        type="zmiany-lokatorskie"
      />
    </Layout>
  )
}

export default Friends
